import service from './request'
//接口前缀
const GPS_PREFIX = '/gps';

/* ----------------- 监控模块 ---------------------- */

// 区域查车（当前车辆）
const queryCurrentPassCar = data => {
  return service({
    url: '/base/monitor/regionSearch',
    method: 'get',
    data
  })
}

// 查询历史区域车辆
const queryHistoryPassCar = data => {
  return service({
    url: '/base/monitor/regionSearch/history',
    method: 'get',
    data
  })
}



// 设置终端参数
const setDeviceParams = data => {
  return service({
    url: '/base/device/setDeviceParams',
    method: 'post',
    data
  })
}

// 查询终端参数
const queryDeviceParams = data => {
  return service({
    url: '/base/device/queryDeviceParams',
    method: 'get',
    data
  })
}

/**
 * 历史视频上传
 *
 */

const historyVideoCheck = data => {
  return service({
    url: '/base/vehicleVideo/historyVideoCheck',
    method: 'post',
    data
  })
}

export {
  queryCurrentPassCar,
  queryHistoryPassCar,
  setDeviceParams,
  queryDeviceParams,
  historyVideoCheck
}

/* ----------------- 监控模块 ---------------------- */

/* ----------------- 位置模块 ---------------------- */

// 新增位置
const insertLocation = data => {
  return service({
    url: '/base/location/insert',
    method: 'post',
    data
  })
}
//新增区域设置 谢 20210826
const insertEnclosure = data => {
  return service({
    url: '/base/enclosure/insert',
    method: 'post',
    data
  })
}

// 编辑位置
const updateLocation = data => {
  return service({
    url: '/base/location/update',
    method: 'post',
    data
  })
}

// 删除位置
const delLocation = data => {
  return service({
    url: '/base/location/deleteById',
    method: 'get',
    data
  })
}
//删除报警区域
const delEnclosure = data => {
  return service({
    url: '/base/enclosure/deleteById',
    method: 'delete',
    data
  })
}

// 分页查询列表位置
const getLocationList = data => {
  return service({
    url: '/base/location/queryList',
    method: 'get',
    data
  })
}

//分页列表查询--报警 谢20210826
const getEnclosureList = data => {
  return service({
    url: '/base/enclosure/queryList',
    method: 'post',
    data
  })
}

//修改区域 20210826
const updateEnclosure = data => {
  return service({
    url: '/base/enclosure/update',
    method: 'post',
    data
  })
}

//区域详情
const queryEnclosureDetail = data => {
  return service({
    url: '/base/enclosure/queryEnclosureDetail',
    method: 'get',
    data
  })
}

// 车辆绑定区域查询
const queryVehicleList = data => {
  return service({
    url: '/base/location/queryVehicleList',
    method: 'post',
    data
  })
}

export {
  insertLocation,
  updateLocation,
  delLocation,
  getLocationList,
  queryVehicleList,
  insertEnclosure,
  getEnclosureList,
  updateEnclosure,
  queryEnclosureDetail,
  delEnclosure
}

/* ----------------- 位置模块 ---------------------- */

/* ----------------- 风险源模块 ---------------------- */

// 风险源列表
const getDangerSourceList = data => {
  return service({
    url: `${GPS_PREFIX}/SectionWarManager/sections`,
    method: 'post',
    data
  })
}

export {
  getDangerSourceList
}

/* ----------------- 风险源模块 ---------------------- */

/* ----------------- 客车行程管理模块 ---------------------- */
// 新增客车行程
const addJourney = data => {
  return service({
    url: `${GPS_PREFIX}/journeyVehicle/insert`,
    method: 'post',
    data
  })

}
// 修改客车行程
const editJourney = data => {
  return service({
    url: `${GPS_PREFIX}/journeyVehicle/updateById`,
    method: 'put',
    data
  })
}

// 删除客车行程
const deleteJourney = data => {
  return service({
    url: `${GPS_PREFIX}/journeyVehicle/delete/${data}`,
    method: 'delete'
  })
}
// 查询-客车行程列表
const queryJourneyList = data => {
  return service({
    url: `${GPS_PREFIX}/journeyVehicle/queryJourneyVehicleByQuery`,
    method: 'post',
    data
  })
}

//报警报备
const queryPageByQuery = data => {
  return service({
    url: `/base/alarmInform/queryPageByQuery`,
    method: 'post',
    data
  })
}
//新增报备数据
const insetAlarmInform = data => {
  return service({
    url: `/base/alarmInform/insert`,
    method: 'post',
    data
  })
}
//查看报备详情
const getAlarmInformById = data => {
  return service({
    url: `/base/alarmInform/queryDetailById`,
    method: 'get',
    data
  })
}
//编辑报备
const updateAlarmInformById = data => {
  return service({
    url: `/base/alarmInform/update`,
    method: 'put',
    data
  })
}
//删除报备
const delAlarmInformById = data => {
  return service({
    url: `/base/alarmInform/deleteById`,
    method: 'delete',
    data
  })
}

// 查询单个客车行程
const getJourneyById = data => {
  return service({
    url: `${GPS_PREFIX}/journeyVehicle/getJourneyVehicleById`,
    method: 'get',
    data
  })
}
export {
  addJourney,
  editJourney,
  deleteJourney,
  queryJourneyList,
  getJourneyById,
  queryPageByQuery,
  insetAlarmInform,
  getAlarmInformById,
  updateAlarmInformById,delAlarmInformById
}

/* ----------------- 客车行程管理模块 ---------------------- */

/* ----------------- 五不出站管理模块 ---------------------- */
// 新增五不出站
const addOutbound = data => {
  return service({
    url: `${GPS_PREFIX}/outbound/insert`,
    method: 'post',
    data
  })

}
// 修改五不出站
const editOutbound = data => {
  return service({
    url: `${GPS_PREFIX}/outbound/updateById`,
    method: 'put',
    data
  })
}

// 删除五不出站
const deleteOutbound = data => {
  return service({
    url: `${GPS_PREFIX}/outbound/delete/${data}/`,
    method: 'delete'
  })
}
// 查询-五不出站列表
const queryOutboundList = data => {
  return service({
    url: `${GPS_PREFIX}/outbound/queryOutboundByQuery`,
    method: 'post',
    data
  })
}

// 查询单个五不出站
const getOutboundById = data => {
  return service({
    url: `${GPS_PREFIX}/outbound/getOutboundById`,
    method: 'get',
    data
  })
}
export {
  addOutbound,
  editOutbound,
  deleteOutbound,
  queryOutboundList,
  getOutboundById
}

/* ----------------- 五不出站管理模块 ---------------------- */

/* ----------------- 危险品运输管理模块 ---------------------- */
// 新增危险品
const addDangerous = data => {
  return service({
    url: `${GPS_PREFIX}/dangerousMaterial/insert`,
    method: 'post',
    data
  })

}
// 修改危险品
const editDangerous = data => {
  return service({
    url: `${GPS_PREFIX}/dangerousMaterial/updateById`,
    method: 'put',
    data
  })
}

// 删除危险品
const deleteDangerous = data => {
  return service({
    url: `${GPS_PREFIX}/dangerousMaterial/delete/${data}`,
    method: 'delete'
  })
}
// 查询-危险品列表
const queryDangerousList = data => {
  return service({
    url: `${GPS_PREFIX}/dangerousMaterial/queryDangerousMaterialByQuery`,
    method: 'post',
    data
  })
}

// 查询单个危险品
const getDangerousById = data => {
  return service({
    url: `${GPS_PREFIX}/dangerousMaterial/getDangerousMaterialById`,
    method: 'get',
    data
  })
}
export {
  addDangerous,
  editDangerous,
  deleteDangerous,
  queryDangerousList,
  getDangerousById
}

/* ----------------- 危险品运输管理模块 ---------------------- */

/* ----------------- 行驶统计模块 ---------------------- */
//查询-IC卡使用统计
const queryStatisticsPage = data => {
  return service({
    url: `/report/icCard/queryStatisticsPage`,
    method: 'post',
    data
  })
}
//查询-IC卡使用详情
const queryDetailsPage = data => {
  return service({
    url: `/report/icCard/queryDetailsPage`,
    method: 'post',
    data
  })
}
//
// 查询-里程轨迹统计
const queryMileForm = data => {
  return service({
    url: `/report/vehicleStatistics/mile`,
    method: 'post',
    data
  })
}

// 查询-车辆在线率
const queryIntervalForm = data => {
  return service({
    url: `/report/vehicleStatistics/online`,
    method: 'post',
    data
  })
}

// 查询-离线车辆统计
const queryOfflineForm = data => {
  return service({
    url: `/report/vehicleStatistics/offline`,
    method: 'post',
    data
  })
}

// 查询-定位统计
const queryLocationForm = data => {
  return service({
    url: `/report/vehicleStatistics/location`,
    method: 'post',
    data
  })
}

// 查询-登签退签
const queryDriverSignForm = data => {
  return service({
    url: `/report/vehicleStatistics/driverSign`,
    method: 'post',
    data
  })
}

// 查询-设备故障
const queryDeviceFaultForm = data => {
  return service({
    url: `/report/vehicleStatistics/deviceFault`,
    method: 'post',
    data
  })
}
// 查询-设备故障--详情
const queryFaultDetail = data => {
  return service({
    url: `/report/vehicleStatistics/queryFaultDetail`,
    method: 'post',
    data
  })
}
//漂移车辆查询
const queryDriftForm = data => {
  return service({
    url: `/report/drift/list`,
    method: 'post',
    data
  })
}
//漂移车辆详情
const queryDriftDetail = data => {
  return service({
    url: `/report/drift/detail`,
    method: 'post',
    data
  })
}
//驾驶时长分页统计
const queryDurationByPage = data => {
  return service({
    url: `/report/drivingDuration/queryDurationByPage`,
    method: 'post',
    data
  })
}


export {
  queryStatisticsPage,
  queryDetailsPage,
  queryMileForm,
  queryIntervalForm,
  queryOfflineForm,
  queryLocationForm,
  queryDriverSignForm,
  queryDeviceFaultForm,
  queryDriftForm,
  queryDriftDetail,
  queryDurationByPage,
  queryFaultDetail
}

/* ----------------- 行驶统计模块 ---------------------- */

/* ----------------- 报警统计模块 ---------------------- */

// 查询-超速报警车辆统计
const querySpeedStatistics = data => {
  return service({
    url: `/report/illegal/querySpeedOverVehicleStatistics`,
    method: 'post',
    data
  })
}

// 查询-超速报警企业统计
const querySpeedCompanyStatistics = data => {
  return service({
    url: `/report/illegal/querySpeedOverCompanyStatistics`,
    method: 'post',
    data
  })
}

// 查询-疲劳驾驶车辆统计
const queryFatigueVehicleStatistics = data => {
  return service({
    url: `/report/illegal/queryFatigueVehicleStatistics`,
    method: 'post',
    data
  })
}

// 查询-疲劳驾驶企业统计
const queryFatigueCompanyStatistics = data => {
  return service({
    url: `/report/illegal/queryFatigueCompanyStatistics`,
    method: 'post',
    data
  })
}

// 查询-车辆报警车辆统计
const queryAlarmVehicleAnalysis = data => {
  return service({
    url: `/report/illegal/queryAlarmVehicleAnalysis`,
    method: 'post',
    data
  })
}

//查询 - 车辆报警率统计
const queryAlarmDisposeAnalysis = data => {
  return service({
    url: `/report/alarm/dispose/analysis/alarmDisposeAnalysis`,
    method: 'post',
    data
  })
}

// 查询-车辆报警企业统计
const queryCompanyAnalysis = data => {
  return service({
    url: `/report/illegal/queryCompanyAnalysis`,
    method: 'post',
    data
  })
}

// 查询-驾驶员报警详情
const queryDriverAnalysis = data => {
  return service({
    url: `/report/illegal/queryDriverAnalysisPageByQuery`,
    method: 'post',
    data
  })
}

// 查询-驾驶员行为分析统计
const queryDriverOnCompanyAnalysis = data => {
  return service({
    url: `/report/illegal/queryDriverOnCompanyAnalysis`,
    method: 'post',
    data
  })
}

// 查询-车辆报警类型统计
const queryAlarmTypeStatistics = data => {
  return service({
    url: `/report/illegal/queryAlarmTypeStatisticsPageByQuery`,
    method: 'post',
    data
  })
}

// 查询-用户登录日志详情
const queryUserLoginHistoryCountDetail = data => {
  return service({
    url: `/report/manageStatistics/queryUserLoginHistoryCountDetail`,
    method: 'post',
    data
  })
}

// 查询-用户登录日志统计
const queryUserLoginHistoryToCompany = data => {
  return service({
    url: `/report/manageStatistics/queryUserLoginHistoryToCompany`,
    method: 'post',
    data
  })
}

//报警附件列表
const externalList = data => {
  return service({
    url: `/report/external/list`,
    method: 'post',
    data
  })
}



export {
  querySpeedStatistics,
  querySpeedCompanyStatistics,
  queryFatigueVehicleStatistics,
  queryFatigueCompanyStatistics,
  queryAlarmVehicleAnalysis,
  queryAlarmDisposeAnalysis,
  queryCompanyAnalysis,
  queryDriverAnalysis,
  queryDriverOnCompanyAnalysis,
  queryAlarmTypeStatistics,
  queryUserLoginHistoryCountDetail,
  queryUserLoginHistoryToCompany,
  externalList
}
/* ----------------- 报警统计模块 ---------------------- */

/* ----------------- 管理行为分析模块 ---------------------- */
// 查询-抽查明细查看
const querySpotCheckDetail = data => {
  return service({
    url: `/report/manageStatistics/querySpotCheckDetailPageByQuery`,
    method: 'post',
    data
  })
}

// 查询-抽查车辆统计
const querySpotCheckGroupVehicle = data => {
  return service({
    url: `/report/manageStatistics/querySpotCheckGroupVehiclePageByQuery`,
    method: 'post',
    data
  })
}

// 查询-抽查企业统计
const querySpotCheckGroupCompany = data => {
  return service({
    url: `/report/manageStatistics/querySpotCheckGroupCompanyPageByQuery`,
    method: 'post',
    data
  })
}

// 查询-抽查用户统计
const querySpotCheckGroupUser = data => {
  return service({
    url: `/report/manageStatistics/querySpotCheckGroupUserPageByQuery`,
    method: 'post',
    data
  })
}
export {
  querySpotCheckDetail,
  querySpotCheckGroupVehicle,
  querySpotCheckGroupCompany,
  querySpotCheckGroupUser
}

// 查询-车辆调度-明细查看
const scheduleVehicleDetail = data => {
  return service({
    url: `/report/schedule/scheduleVehicleDetail`,
    method: 'post',
    data
  })
}

// 查询-车辆调度-车辆统计
const scheduleVehicleCount = data => {
  return service({
    url: `/report/schedule/scheduleVehicleCount`,
    method: 'post',
    data
  })
}

// 查询-车辆调度-企业统计
const scheduleCompanyCount = data => {
  return service({
    url: `/report/schedule/scheduleCompanyCount`,
    method: 'post',
    data
  })
}
export {
  scheduleVehicleDetail,
  scheduleVehicleCount,
  scheduleCompanyCount
}

/* ----------------- 管理行为分析模块 ---------------------- */
/* ----------------- 数据看板模块 ---------------------- */
// 查询-数据看板-车辆在线率
const getOnlineRate = data => {
  return service({
    url: `/report/vehicleStatistics/onlineRate`,
    method: 'get',
    data
  })
}

// 查询-数据看板-ADAS报警
const getAlarmAdas = data => {
  return service({
    url: `/report/vehicleStatistics/alarmAdas`,
    method: 'get',
    data
  })
}

// 查询-数据看板-DSM报警
const getAlarmDsm = data => {
  return service({
    url: `/report/vehicleStatistics/alarmDsm`,
    method: 'get',
    data
  })
}

// 查询-数据看板-报警车辆排行
const queryAlarmRankVehicle = data => {
  return service({
    url: `/report/dataNavigation/queryAlarmRankVehicle`,
    method: 'post',
    data
  })
}

// 查询-数据看板-百公里报警趋势
const queryAvgAlarm = data => {
  return service({
    url: `/report/dataNavigation/queryAvgAlarm`,
    method: 'post',
    data
  })
}

// 查询-数据看板-报警统计
const queryAlarmTotal = data => {
  return service({
    url: `/report/dataNavigation/queryAlarmTotal`,
    method: 'post',
    data
  })
}

// 查询-数据看板-违章报警车辆统计
const queryIllegalVehicleTotal = data => {
  return service({
    url: `/report/dataNavigation/queryIllegalVehicleTotal`,
    method: 'post',
    data
  })
}
// 查询-数据看板-车辆数量统计
const queryVehicleCount = data => {
  return service({
    url: `/base/index/vehicleCount`,
    method: 'get',
    data
  })
}
export {
  getOnlineRate,
  getAlarmAdas,
  getAlarmDsm,
  queryAlarmRankVehicle,
  queryAvgAlarm,
  queryAlarmTotal,
  queryIllegalVehicleTotal,
  queryVehicleCount
}

/* ----------------- 数据看板模块 ---------------------- */


// 平台查岗-新增提问
const platformQuestionInsertApi = data => {
  return service({
    url: `/base/platformQuestion/insert`,
    method: 'post',
    data
  })
}
//平台查岗-列表
const platformQuestionPageApi = data => {
  return service({
    url: `/base/platformQuestion/queryPage`,
    method: 'post',
    data
  })
}
//获取详情
const platformQuestionInfoApi = data => {
  return service({
    url: `/base/platformQuestion/load`,
    method: 'get',
    data
  })
}
//抽查人列表
const queryQuestionMsgListApi = data => {
  return service({
    url: `/base/platformQuestionDetail/queryQuestionMsgList`,
    method: 'get',
    data
  })
}
//平台查岗-回复
const restoreQuestionApi = data => {
  return service({
    url: `/base/platformQuestionDetail/restoreQuestion`,
    method: 'put',
    data
  })
}
//新增大屏监控消息通知
const insertGpsMonitorMsgApi = data => {
  return service({
    url: `/base/gpsMonitorMsg/insert`,
    method: 'post',
    data
  })
}
//大屏监控消息通知-列表
const gpsMonitorMsgPageApi = data => {
  return service({
    url: `/base/gpsMonitorMsg/queryPage`,
    method: 'post',
    data
  })
}
//大屏监控消息通知-获取默认消息
const getDefaultMsgApi = data => {
  return service({
    url: `/base/gpsMonitorMsg/getDefaultMsg`,
    method: 'get',
    data
  })
}

//大屏监控消息通知-设置默认消息
const setGpsDefaultMonitorMsgApi = data => {
  return service({
    url: `/base/gpsMonitorMsg/setGpsDefaultMonitorMsg`,
    method: 'post',
    data
  })
}

export{
  platformQuestionInsertApi,
  platformQuestionPageApi,
  platformQuestionInfoApi,
  queryQuestionMsgListApi,
  restoreQuestionApi,
  insertGpsMonitorMsgApi,
  gpsMonitorMsgPageApi,
  getDefaultMsgApi,
  setGpsDefaultMonitorMsgApi
}

/**---------------------------双随机抽检（报警合并）--------------------------------- */


//报警合并查询-分页
const queryCheckMergeAlarmPageListAPI = data => {
  return service({
    url: `/base/checkMergeAlarm/queryPageList`,
    method: 'post',
    data
  })
}
//报警基础详情
const queryAlarmByAlarmNoAPI = data => {
  return service({
    url: `/base/checkMergeAlarm/queryAlarmByAlarmNo`,
    method: 'get',
    data
  })
}
//报警合并详情
const queryAlarmDetailListAPI = data => {
  return service({
    url: `/base/checkMergeAlarm/queryAlarmDetailList`,
    method: 'post',
    data
  })
}
//报警轨迹
const queryAlarmTraceAPI = data => {
  return service({
    url: `/base/checkMergeAlarm/alarmTrace`,
    method: 'get',
    data
  })
}
//车辆在线率-公司
const queryOnlineCompanyStatisticsAPI = data => {
  return service({
    url: `/report/vehicleCheckOnlineRate/onlineCompanyStatistics`,
    method: 'post',
    data
  })
}
//车辆在线率-车辆
const queryVehicleStatisticsAPI = data => {
  return service({
    url: `/report/vehicleCheckOnlineRate/queryVehicleStatistics`,
    method: 'post',
    data
  })
}

//历史数据连续-分页查询
const queryTravelContinuousPageAPI = data => {
  return service({
    url: `/report/travelContinuous/queryTravelContinuousPage`,
    method: 'post',
    data
  })
}
//历史数据连续-详情
const queryDetailAPI = data => {
  return service({
    url: `/report/travelContinuous/queryDetail`,
    method: 'post',
    data
  })
}
//历史数据连续-轨迹
const historyTrackNewAPI = data => {
  return service({
    url: `/base/monitor/historyTrackNew`,
    method: 'get',
    data
  })
}
//报警合并-超速报警统计
const queryCompanyStatisticsAPI = data => {
  return service({
    url: `/report/checkVehicleSpeed/queryCompanyStatistics`,
    method: 'post',
    data
  })
}
//报警合并-车辆统计接口
const queryCheckVehicleStatisticsAPI = data => {
  return service({
    url: `/report/checkVehicleSpeed/queryVehicleStatistics`,
    method: 'post',
    data
  })
}

//
const queryCompanyFatigueStatisticsAPI = data => {
  return service({
    url: `/report/checkVehicleFatigue/queryCompanyStatistics`,
    method: 'post',
    data
  })
}
const queryVehicleFatigueStatisticsAPI = data => {
  return service({
    url: `/report/checkVehicleFatigue/queryVehicleStatistics`,
    method: 'post',
    data
  })
}
export{
  queryCheckMergeAlarmPageListAPI,
  queryAlarmByAlarmNoAPI,
  queryAlarmDetailListAPI,
  queryAlarmTraceAPI,
  queryOnlineCompanyStatisticsAPI,
  queryVehicleStatisticsAPI,
  queryTravelContinuousPageAPI,
  historyTrackNewAPI,
  queryDetailAPI,
  queryCompanyStatisticsAPI,
  queryCheckVehicleStatisticsAPI,
  queryCompanyFatigueStatisticsAPI,
  queryVehicleFatigueStatisticsAPI,
}